@import "@angular/cdk/overlay-prebuilt.css";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

// Import Bootstrap utilities and mixins if needed
@import "../node_modules/bootstrap/scss/mixins";

#gt-logo-home {
  display: flex;
  cursor: pointer;
}

:root {
  // --color-basic-100: #f7f9fc;
  // --color-warning-500: #ffa100;
  // --color-basic-text: #222b45;
  // --color-primary-100: #3366ff;
  // --color-primary-500: #0043ff;
  // --color-info-500: #0095ff;
  // --color-success-500: #00d68f;

  // --bs-primary: #0080d9;
  --bs-primary: #0080d9;

  .text-primary {
    // color: #0080d9;
    color: #0080d9;
  }

  // TO Use Bootstrap variabl we can use this:
  --bs-btn-disabled-opacity: 0.35;

  --bs-dark: #121212;

  --color-basic-100: #{$gray-200};
  --color-warning-500: #{$warning};
  --color-basic-text: #ffff;
  --color-primary-100: #67d7ef;
  --color-primary-500: #{$blue-500};
  --color-info-500: #{$info};
  --color-success-500: #{$success};
  --bs-danger: #{$red-600};
  --color-danger-300: #e08565;
  --color-basic-1100: #000000;
}

body {
  font-family: "Poppins";
  font-size: 0.95rem;
}

// BOOTSTRAP OVERRIDES
.bg-primary {
  background-color: #0080d9 !important;
  color: #fff;
}

.border-primary {
  border-color: #0080d9 !important;
  color: #0080d9;
}

.text-primary {
  color: #0080d9 !important;
}

.btn-primary {
  --bs-btn-bg: #0080d9;
  --bs-btn-border-color: #0080d9;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-disabled-bg: #0080d9;
  --bs-btn-disabled-border-color: #0080d9;
}

.btn-outline-primary {
  --bs-btn-color: #0080d9;
  --bs-btn-border-color: #0080d9;
  --bs-btn-hover-bg: #0080d9;
  --bs-btn-hover-border-color: #0080d9;
  --bs-btn-active-bg: #0080d9;
  --bs-btn-active-border-color: #0080d9;
  --bs-btn-disabled-color: #0080d9;
  --bs-btn-disabled-border-color: #0080d9;
}

.btn-primary:hover {
  // background-color: #0080d9 !important;
  // border-color: #0080d9 !important;
  background-color: #0080d9 !important;
  border-color: #0080d9 !important;
}

.btn {
  text-transform: uppercase;
}

.btn-dashboard {
  height: 3.6rem;
  --bs-btn-font-weight: 700;
  font-size: 0.85rem;
}

.form-select-lg {
  font-size: 1rem !important;
}

.nav-link:hover,
.nav-link:focus {
  color: #0080d9;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #0080d9 !important;
}

.user-menu-content {
  text-align: right;
  font-size: 14px;
  padding: 1rem;
  margin: 0.75rem 0;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500;
}

.form-control:focus {
  border-color: #0080d9;
  box-shadow: 0 0 0 0.25rem #0080d957;
}

.form-check-input:checked {
  background-color: #0080d9;
  border-color: #0080d9;
}

.card {
  border-color: #495057;
}

// Where is this td styling being used?
td {
  padding: 0.6rem 0.25rem 0.6rem 0.25rem;
}

.details-card {
  dl {
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    color: var(--card-text-color);
    background-color: var(--card-bg-color);
    background-image: var(--card-bg-gradient);
    margin-bottom: 0.5rem;
    // added min height so that there will touch area for pressing icons where there is no dd
    min-height: 3.5rem;
  }
}

dd {
  margin-bottom: 0;
}

dt {
  font-size: 0.8rem;
  opacity: 0.7;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 0;

  svg {
    // color: #aaa !important;
    min-height: 1rem;
    margin-left: 0.5rem;
  }
}

.manager-notes {
  background-image: var(--card-bg-manager-notes) !important;
  color: var(--card-text-manager-notes) !important;
  background-color: var(--card-bg-manager-notes) !important;
}

.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer;
}

// /BOOTSTRAP OVERRIDES END
.user-icon,
.sidebar-user-icon {
  border: 2px solid var(--bs-body-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.sidebar-user-icon {
  // same as the btn height
  width: 48px;
  height: 48px;
}

.map {
  height: 45vh;
  max-height: 45vh;
  width: 100%;

  input[type="radio"] {
    display: inherit !important;
  }
}

.leaflet-popup-content {
  margin: 0rem !important;
}

.leaflet-popup-content-wrapper {
  background-color: #000;
}

.gt-loader-container {
  background-color: rgb(0 0 0 / 77%);
  z-index: 1600;
  position: fixed;
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gt-loader {
  width: 96px !important;
  height: 96px !important;
  border-width: 6px !important;
  top: 40%;
  position: absolute;
}

// credit card
.gt-payment-card {
  height: 185px;
  width: 295px;
  perspective: 600px;
  /* Adjust background color */
  border-radius: 10px;
  border: 1px solid var(--background-basic-color-4);
  /* Adjust border color */
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.gt-payment-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  /* Add shadow on hover */
}

.card__front {
  flex-grow: 1;

  background-color: #848484;
  background-image: linear-gradient(#575757, #f5f5f5);
  color: #000;
}

.card__label {
  font-weight: bold;
}

.card__part {
  border-radius: 10px;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  padding: 0.5rem;
}

.card_number {
  display: flex;
  width: 100%;
  word-spacing: 4px;
  font-size: 1.1rem;
  letter-spacing: 2px;
  color: var(--color-basic-1100);
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  justify-content: center;
}

.card__space-75 {
  width: 75%;
  float: left;
}

.card__space-25 {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.card__label {
  font-size: 10px;
  text-transform: uppercase;
  color: var(--color-basic-1100);
  letter-spacing: 1px;
}

.card__info {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 18px;
  color: var(--color-basic-1100);
  text-transform: uppercase;
}

.card__primary {
  position: absolute;
  color: var(--color-success-500);
}

.card__inactive {
  opacity: 0.6;
  background-image: none;
}

.card__btn-new {
  height: 185px;
  width: 295px;
  perspective: 600px;
  background-color: #f5f5f5;
  // background-color: var(--background-basic-color-2);
  border-radius: 10px;
  border: 1px solid var(--color-basic-600);
  // margin: 5px;
  display: flex;
  justify-content: center;
  padding: 0;
  flex-direction: row;
  align-items: center;
  color: #323232;
}

.card__container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.delete-icon {
  position: fixed;
  right: 5px;
  bottom: 5px;
}

.delete-icon-color {
  color: var(--color-basic-900);
  font-size: 1.5rem;
}

// login and registration page and other auth component pages

.gt-auth-block {
  margin: 0px auto !important;
  padding-top: 2rem !important;
  max-width: 33rem !important;
}

.gt-logo-mobile {
  display: flex;
  justify-content: center;
  height: 10rem;
  margin-bottom: 2.5rem;
}

.gt-logo-desktop {
  display: flex;
  justify-content: center;
  margin-top: 5rem !important;
  height: 6rem;
  margin-bottom: 5rem;
}

// login and registration page and other auth component pages ENDS
.nav-link {
  cursor: pointer;
}

.events-details-section {
  font-size: 0.75rem;
  padding: 0.25rem;
  word-break: break-all;
}

.autocomplete-dropdown {
  max-height: 25vh;
  overflow-y: auto;
}

.small-font {
  font-size: 0.75rem !important;
}

.overlay-backdrop {
  background-color: #160101;
}

// Overlay panel styling
.overlay-panel {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 7px;
}

// MAP's component CSS
//Circle showing in guards online page
.custom-marker-icon {
  background-color: var(--bs-dark);
  border-color: var(--bs-dark);
  color: var(--color-basic-100);
  width: 2.5rem !important;
  height: 2.5rem !important;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  font-weight: 600;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 1.1rem;
  border: 2px solid;
  margin-left: -20px !important;
  margin-top: 0px !important;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.custom-marker-icon-img {
  width: 2.5rem !important;
  height: 2.5rem !important;
  border-radius: 50%;
  object-fit: cover;
  /* Ensures the image covers the entire circle */
}

.marker-cluster-small {
  background-color: #0080d9 !important;
  color: var(--color-basic-100);
  border-radius: 20px;
}

.marker-cluster-small div {
  background-color: #0080d9 !important;
  color: var(--color-basic-100);
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font-size: 14px;
  font-family: "Poppins";
  line-height: 30px;
  font-weight: bold;
}

svg {
  cursor: pointer;
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-border-color) !important;
}

// Media Query CSS
@media (min-width: 320px) {
  .dialog-box {
    width: 95vw;
    position: fixed !important;
    top: 3.5rem;
    left: 50%;
    transform: translateX(-50%);
    max-height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0.5rem;
    // background-image: linear-gradient(to bottom right, #1d1d1d, #323232);
  }

  #gt-logo-home {
    height: 2.5rem;
    width: 2.5rem;
  }

  .map {
    max-height: 30vh;

    input[type="radio"] {
      display: inherit !important;
    }
  }

  .btn-md {
    min-height: 3rem;

    --bs-btn-font-weight: 600;
    font-size: 0.75rem;
  }

  .btn-lg {
    min-height: 3.6rem;

    --bs-btn-font-weight: 700;
    font-size: 0.85rem;
  }

  .btn-xl {
    min-height: 4.5rem;

    --bs-btn-font-weight: 800;
    font-size: 0.9rem;
  }

  .btm-btn-height {
    height: 3.6rem;
    text-transform: uppercase;
    --bs-btn-font-weight: 700;
    font-size: 0.9rem;
  }

  // commented out as the small font makes the text too small needlessly
  // .input-font-sm {
  //   font-size: small;
  // }

  svg {
    min-height: 0.9rem !important;
  }

  input[type="checkbox"] {
    width: 1.2rem !important;
    height: 1.2rem !important;
    margin-right: 0.1rem !important;
  }
}

@media screen and (min-width: 576px) {
  #gt-logo-home {
    height: 3rem;
    width: 3rem;
  }

  // Date Time range font
  .input-font-sm {
    font-size: medium;
  }
}

@media screen and (min-width: 768px) {
  .dialog-box {
    padding: 0.8rem;
    width: 40vw;
    top: 7rem;
    left: 50%;
    transform: translate(-50%);
    max-height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .map {
    max-height: 45vh;

    input[type="radio"] {
      display: inherit !important;
    }
  }

  .btn-xs {
    font-size: 0.625rem;
    line-height: 0.75rem;
    padding: 0.3125rem 0.625rem;
  }

  .btn-sm {
    // height: 2rem;
    font-size: 0.7rem;
    line-height: 1rem;
    padding: 0.4375rem 0.875rem;
    text-transform: uppercase;
    --bs-btn-font-weight: 700;
  }

  .btn-md {
    // height: ;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    padding: 0.6875rem 1.125rem;
    text-transform: uppercase;
  }

  .btn-lg {
    font-size: 0.8rem !important;
    line-height: 1.25rem !important;
    padding: 0.8125rem 1.125rem;
  }

  .btn-xl {
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 0.9375rem 1.375rem;
  }
}

.card-header {
  margin-bottom: 1rem;
  text-align: center;

  svg {
    height: 1.5rem;
  }
}

.input-btn-group {
  // this class is used for styling the lookup and buttons(mainly add item btn) that is present in the list pages
  padding-top: 0.5rem;
  // .btn {
  //   height: 100%;
  // }
}

.gt-card {
  // similar to the one used inside the roster -scheduler
  border-radius: 7px;
  padding: 0.5rem;
  color: var(--card-text-color);
  background-color: var(--card-bg-color);
  background-image: var(--card-bg-gradient);
  height: 100%;
}

.bottom-section-buttons {
  display: block;
  background-color: var(--bs-body-bg);
  z-index: 50;
  position: fixed;
  bottom: 0;
  left: calc(var(--bs-gutter-x) * 0.5);
  right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.bottom-section {
  display: block;
  background-color: var(--bs-body-bg);
  z-index: 50;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.roster-table {
  overflow-y: auto;
  width: 100%;

  table {
    border-collapse: collapse;
    min-width: 100%;
    table-layout: fixed;
    max-height: 100%;
    width: 100%;

    thead {
      padding: 0.5rem;
      top: 0;
      position: sticky;
      text-align: center;
      z-index: 1;

      tr,
      th,
      td {
        padding: 0.5rem;
        left: 0;
      }

      .addJob {
        display: none;
      }

      th:hover .addJob {
        align-items: center;
        background-color: #f8f9fa;
        border-radius: 5px;
        color: #000000;
        display: flex;
        font-weight: 600;
        height: 2rem;
        justify-content: center;
        margin-left: 1rem;
        text-align: center;
        width: 2rem;
        cursor: pointer;
      }

      .weekday {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0080d9;
        color: #ffffff;
        padding: 0.5rem 0;
      }
    }

    td .client-name {
      display: block;
      height: 30px;
      text-transform: uppercase;
    }

    tbody {
      tr,
      th,
      td {
        padding: 0.7rem;
        vertical-align: top;
      }
    }

    tr:first-child th {
      border-top: 0;
      border-bottom: 0;
      border-right: 0 !important;

      th:last-child {
        border-right: 0 !important;
      }
    }

    tr:last-child td {
      border-bottom: 0;
    }

    tr:first-child,
    tr:last-child {
      border-left: 0;
    }

    th:first-child,
    td:first-child {
      border-left: 0;
    }

    th:last-child,
    td:last-child {
      border-right: 0;
    }

    .merged-cell {
      position: relative;
    }

    .merged-cell::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: white;
      z-index: 1;
    }

    .merged-content {
      position: relative;
      z-index: 2;
    }
  }
}

.cursor-grab {
  cursor: grab !important;
}

.gt-multi-line {
  white-space: pre-line;
}

svg.svg-inline--fa.fa-bars {
  cursor: move;
}

// Custom Tooltip arrow color
.custom-tooltip-arrow:before {
  border-bottom-color: #991b0d !important;
}

body.no-scroll {
  overflow: hidden !important;
}

.row {
  --bs-gutter-x: 0.5rem !important;
  --bs-gutter-y: 0.5rem !important;
}

.roster-table {
  overflow-y: auto;
  width: 100%;

  table {
    border-collapse: collapse;
    min-width: 100%;
    table-layout: fixed;
    max-height: 100%;
    width: 100%;

    thead {
      padding: 0.5rem;
      top: 0;
      position: sticky;
      text-align: center;
      z-index: 1;

      tr,
      th,
      td {
        padding: 0.5rem;
        left: 0;
      }

      .addJob {
        display: none;
      }

      th:hover .addJob {
        align-items: center;
        background-color: #f8f9fa;
        border-radius: 5px;
        color: #000000;
        display: flex;
        font-weight: 600;
        height: 2rem;
        justify-content: center;
        margin-left: 1rem;
        text-align: center;
        width: 2rem;
        cursor: pointer;
      }

      .weekday {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0080d9;
        color: #ffffff;
        padding: 0.5rem 0;
      }
    }

    td .client-name {
      display: block;
      height: 30px;
      text-transform: uppercase;
    }

    tbody {
      tr,
      th,
      td {
        padding: 0.7rem;
        vertical-align: top;
      }
    }

    tr:first-child th {
      border-top: 0;
      border-bottom: 0;
      border-right: 0 !important;

      th:last-child {
        border-right: 0 !important;
      }
    }

    tr:last-child td {
      border-bottom: 0;
    }

    tr:first-child,
    tr:last-child {
      border-left: 0;
    }

    th:first-child,
    td:first-child {
      border-left: 0;
    }

    th:last-child,
    td:last-child {
      border-right: 0;
    }

    .merged-cell {
      position: relative;
    }

    .merged-cell::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: white;
      z-index: 1;
    }

    .merged-content {
      position: relative;
      z-index: 2;
    }
  }
}

// Variable for dynamic bg color based on selected theme

[data-bs-theme="dark"] {
  --bg-color: var(--bs-dark);
  --bs-body-bg: #121212;
  --bs-card-bg: #373737;
  --bs-body-color: #fefefe;
  --highlight-bg: #2c3035;
  --highlight-color: var(--bs-body-color);
  --card-text-color: #e5e5e5;
  --card-bg-color: #1d1d1d;
  --card-bg-gradient: linear-gradient(to bottom right, #1d1d1d, #323232);
  --card-bg-manager-notes: linear-gradient(to bottom right, #00000010, #e3f7fd);
  --card-bg-manager-notes: #000;
  --card-text-manager-notes: #e5e5e5;
  --scheduled-item-hover: #202020;

  .btn {
    border-color: #495057;
  }

  // .alert-primary{
  //   --bs-alert-color: #052c65;
  //   --bs-alert-bg: #cfe2ff;
  //   --bs-alert-border-color: #9ec5fe;
  //   --bs-alert-link-color: #052c65;
  // }

  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #121212;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;

  .alert-primary {
    --bs-alert-color: var(--bs-primary-text-emphasis);
    --bs-alert-bg: var(--bs-primary-bg-subtle);
    --bs-alert-border-color: var(--bs-primary-border-subtle);
    --bs-alert-link-color: var(--bs-primary-text-emphasis);
  }
}

[data-bs-theme="light"] {
  --bg-color: var(--bs-light);
  --highlight-bg: var(--bs-dark);
  --highlight-color: var(--bs-light);
  --card-text-color: #333;
  --card-bg-color: #f5f5f5;
  --card-bg-gradient: linear-gradient(to bottom right, #00000010, #e3f7fd);
  --card-bg-manager-notes: linear-gradient(to bottom right, #1d1d1d, #323232);
  --card-bg-manager-notes: #fff;
  --card-text-manager-notes: #333;
  --scheduled-item-hover: #ececec;
}

.overflow-45vh {
  max-height: 45vh;
  overflow-y: auto;
}

.recommendation-alert {
  width: fit-content;
  margin: auto;
  padding: 0.5rem;
}

.md-font-size {
  // used in dl to override the icon size
  font-size: 1.5em;
}

.gc-tooltip {
  // color: #000;
  color: var(--card-text-color);
  background-color: var(--card-bg-color);
  background-image: var(--card-bg-gradient);
  font-family: "Poppins";
}

.gc-copyright {
  display: none !important;
}

.gc-pagination-theme-circles .gc-pagination-item.gc-pagination-active,
.gc-pagination-theme-circles .gc-pagination-item:hover,
.gc-progressbar {
  background-color: var(--bs-primary);
}

.gc-navigation-next:hover,
.gc-navigation-prev:hover {
  border-color: var(--bs-primary);
}

.gc-custom-buttons {
  margin-bottom: 0;
  margin-top: 20px;
  display: flex;
}

.gc-description {
  // font-size: 14px;
  margin-bottom: 10px;
  // max-height: 200px;
  // overflow-y: auto;
  // padding: 0 5px;
  // scroll-behavior: smooth;
  text-align: start;
  line-height: 2;
}

.gc-navigation-next,
.gc-navigation-prev {
  border-bottom: 5px solid #bebebe;
  border-right: 5px solid #bebebe;
  // cursor: pointer;

  padding: 0.85rem;
}

.gc-close {
  // cursor: pointer;
  // display: inline-block;
  // margin: 10px;
  // opacity: .4;
  // overflow: hidden;
  // position: absolute;
  // right: 0;
  // top: 0;
  width: 25px;
  height: 25px;
}

@keyframes jobAlert {
  from {
    color: var(--bs-white);
    background-color: var(--bs-danger);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(240, 26, 26, 0.76);
    -moz-box-shadow: 0px 0px 12px 0px rgba(240, 26, 26, 0.76);
    box-shadow: 0px 0px 12px 0px rgba(240, 26, 26, 0.76);
  }

  to {
    color: var(--bs-white);
    background-color: var(--bs-danger);
    -webkit-box-shadow: 0px 0px 13px 4px rgba(240, 26, 26, 0.76);
    -moz-box-shadow: 0px 0px 13px 4px rgba(240, 26, 26, 0.76);
    box-shadow: 0px 0px 13px 4px rgba(240, 26, 26, 0.76);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
